import { SvgIconProps } from "@material-ui/core"

import {
  IDisturbanceMonitoring,
  TDisturbanceMonitoringActive,
} from "src/data/homes/types/homeTypes"
import { colorScale, colorsV2 } from "src/ui/colors"
import NoiseMonitoringFilledIcon from "src/ui/icons/noise-monitoring-filled.svg"

export function NoiseMonitoringIcon({
  active,
  state,
  iconProps,
}: {
  active: TDisturbanceMonitoringActive | undefined
  state: IDisturbanceMonitoring["state_v2"]
  iconProps?: SvgIconProps
}) {
  if (!active) {
    return (
      <NoiseMonitoringFilledIcon
        fill={colorsV2.neutralDark}
        color={colorScale.koti[400]}
        {...iconProps}
      />
    )
  }

  if (state === "idle") {
    return (
      <NoiseMonitoringFilledIcon
        fill={colorsV2.systemInfoLight}
        color={colorsV2.systemInfoDark}
        {...iconProps}
      />
    )
  }

  return (
    <NoiseMonitoringFilledIcon
      fill={colorsV2.systemErrorLight}
      color={colorsV2.systemErrorDark}
      {...iconProps}
    />
  )
}
