import { SvgIconProps } from "@material-ui/core"

import { IOccupancy } from "src/data/occupancy/types/occupancyTypes"
import { colorScale, colorsV2 } from "src/ui/colors"
import OccupancyFilledIcon from "src/ui/icons/occupancy-detection-filled.svg"

export function OccupancyIcon({
  active,
  status,
  iconProps,
}: {
  active: IOccupancy["active"]
  status: IOccupancy["status"]
  iconProps?: SvgIconProps
}) {
  if (active) {
    if (status === "crowd_detected") {
      return (
        <OccupancyFilledIcon
          fill={colorsV2.systemErrorLight}
          color={colorsV2.systemErrorDark}
          {...iconProps}
        />
      )
    }

    return (
      <OccupancyFilledIcon
        fill={colorsV2.systemInfoLight}
        color={colorsV2.systemInfoDark}
        {...iconProps}
      />
    )
  }

  return (
    <OccupancyFilledIcon
      fill={colorsV2.neutralDark}
      color={colorScale.koti[400]}
      {...iconProps}
    />
  )
}
